import "katex/dist/katex.min.css";
import React, { type CSSProperties } from "react";
import { preprocessLatex } from "../../../common/markdown/latex/latexPreprocesser";
import { getRenderedMarkdown } from "../../../common/markdown";

type LatexViewProps = {
  children: any;
  className?: string;
  style?: CSSProperties;
};

export function LatexView(props: LatexViewProps) {
  const { className, children, style } = props;
  const test = children.replace(/\\n/g, "\n").replace(/\\\\/g, "\\");
  const renderedMarkdown = getRenderedMarkdown(preprocessLatex(test));
  return (
    <div
      style={style}
      className={`latex-container${className ? ` ${className}` : ""}`}
      dangerouslySetInnerHTML={{ __html: renderedMarkdown }}
    />
  );
}
