/* eslint-disable */

import type { KatexOptions as OriginalKatexOptions } from "katex";
import Katex from "katex";
import type MarkdownIt from "markdown-it";
import { tex } from "@mdit/plugin-tex";
import {
  KatexToken,
  MarkdownItKatexOptions,
  TeXTransformer,
} from "../../../components/atoms/latexView/options";

const katexInline = (
  tex: string,
  options: OriginalKatexOptions,
  transformer?: TeXTransformer
): string => {
  let result: string;

  try {
    result = Katex.renderToString(tex, {
      ...options,
      displayMode: false,
    });
  } catch (error) {
    if (options.throwOnError) console.warn(error);

    result = `<span class='katex-error' title='${escapeHtml(
      (error as Error).toString()
    )}'>${escapeHtml(tex)}</span>`;
  }

  return transformer?.(result, false) ?? result;
};

export const escapeHtml = (unsafeHTML: string): string => {
  const replacements = [
    { toFind: "&", replacement: "&amp;" },
    { toFind: "<", replacement: "&lt;" },
    { toFind: ">", replacement: "&gt;" },
    { toFind: '"', replacement: "&quot;" },
    { toFind: "'", replacement: "&#039;" },
  ].map(({ toFind, replacement }) => ({ regex: new RegExp(toFind, "gu"), replacement }));

  let result = unsafeHTML;
  replacements.forEach(({ regex, replacement }) => {
    result = result.replace(regex, replacement);
  });

  return result;
};

const katexBlock = (
  tex: string,
  options: OriginalKatexOptions,
  transformer?: TeXTransformer
): string => {
  let result: string;

  try {
    result = `<p class='katex-block'>${Katex.renderToString(tex, {
      ...options,
      displayMode: true,
    })}</p>\n`;
  } catch (error) {
    if (options.throwOnError) console.warn(error);

    result = `<p class='katex-block katex-error' title='${escapeHtml(
      (error as Error).toString()
    )}'>${escapeHtml(tex)}</p>\n`;
  }

  return transformer?.(result, true) ?? result;
};

// katex를 이용하여 markdown 내에서 latex 수식을 렌더링
export const katex = <MarkdownItEnv = unknown>(
  md: MarkdownIt,
  options: MarkdownItKatexOptions<MarkdownItEnv> = {}
): void => {
  const {
    allowInlineWithSpace = false, // 인라인 수식에 공백을 허용할지 여부
    mathFence = false, // 수식을 “fence” 블록으로 처리할지 여부
    mhchem = false, // 화학 수식을 렌더링하기 위한 mhchem 플러그인 사용 여부
    logger = (errorCode: string): string =>
      errorCode === "newLineInDisplayMode" ? "ignore" : "warn", // 수식 렌더링 오류에 대한 로깅 방식 정의
    transformer, // 렌더링 결과를 추가로 가공하는 함수
    ...userOptions
  } = options;
  if (mhchem) require("katex/contrib/mhchem");

  // MarkdownIt에에 tex 플러그인을 사용
  md.use(tex, {
    allowInlineWithSpace,
    mathFence,
    render: (content: string, displayMode: boolean, env: MarkdownItEnv) => {
      const katexOptions = {
        delimiters: [
          { left: "$$", right: "$$", display: true },
          { left: "$", right: "$", display: false },
          { left: "\\(", right: "\\)", display: false },
          { left: "\\[", right: "\\]", display: true },
          { left: "\\begin{aligned}", right: "\\end{aligned}", display: true },
        ],
        strict: (
          errorCode:
            | "unknownSymbol"
            | "unicodeTextInMathMode"
            | "mathVsTextUnits"
            | "commentAtEnd"
            | "htmlExtension"
            | "newLineInDisplayMode",
          errorMsg: string,
          token: KatexToken
        ): string | void => {
          const logResult = logger(errorCode, errorMsg, token, env);
          return logResult ?? "ignore";
        },
        throwOnError: false,
        ...userOptions,
      };

      return displayMode
        ? katexBlock(content, katexOptions, transformer)
        : katexInline(content, katexOptions, transformer);
    },
  });
};
