import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ocrEditModalAtom } from "../../../../../../application/recoils/modal/atoms";
import Dialog from "@mui/material/Dialog";
import { Box, Button, Grid, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import { Appbar } from "../../../../../../components/molecules/appbar";
import { LatexView } from "../../../../../../components/atoms/latexView";
import TextField from "@mui/material/TextField";
import constants from "../../../../../../asset/constants";
import { Close } from "@material-ui/icons";
import { useSocket } from "../../../../../../providers/socket";
import { semohaeSearchAtom } from "../../../../../../application/recoils/search/atoms";

export default function OcrEditModal(): JSX.Element {
  const [modalData, setModalData] = useRecoilState(ocrEditModalAtom);
  const [ocrText, setOcrText] = useState<string>(modalData.ocrText || "");
  const setLoading = useSetRecoilState(semohaeSearchAtom);
  const { semohaeSocket } = useSocket();

  useEffect(() => {
    if (modalData.isOpen) {
      setOcrText(modalData.ocrText || "");
    }
  }, [modalData.ocrText, modalData.isOpen]);

  const handleClose = useCallback(() => {
    setModalData((prev) => ({ ...prev, isOpen: false }));
  }, [setModalData]);

  const handleOcrDataChange = useCallback(
    (event) => {
      setOcrText(event.target.value);
    },
    [setOcrText]
  );

  const handleComplete = useCallback(() => {
    setModalData((prev) => ({ ...prev, ocrText }));
    if (semohaeSocket) {
      setLoading(true);
      semohaeSocket.emit("message", {
        id: modalData.imageUrl,
        message: ocrText,
      });
    }
    handleClose();
  }, [ocrText, handleClose, setModalData, semohaeSocket, modalData.imageUrl, setLoading]);

  return (
    <Dialog
      open={modalData.isOpen}
      fullScreen
      onClose={handleClose}
      sx={{
        zIndex: 130001,
      }}
    >
      <Box
        sx={{
          maxWidth: "1180px",
          width: "100%",
          margin: "0 auto",
          background: "#F9FAFB",
        }}
      >
        <Appbar>
          <Toolbar
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
            <Typography
              sx={{
                fontSize: "20px",
                color: "#262626",
              }}
            >
              문제 수정
            </Typography>
            <Button
              variant={"contained"}
              sx={{
                background: "#3B82F6",
              }}
              onClick={handleComplete}
            >
              <Typography>완료</Typography>
            </Button>
          </Toolbar>
        </Appbar>
        <Stack
          sx={{
            marginTop: "10px",
          }}
        >
          <Grid container>
            <Grid xs={12} lg={12}>
              <Box px={2}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    color: "#262626",
                    fontWeight: "bold",
                  }}
                >
                  인식된 문제의 내용을 확인해주세요.
                </Typography>
                <Typography
                  sx={{
                    color: "#3B82F6",
                  }}
                >
                  내용이 잘못 되었다면 수정 후 진행하기를 눌러주세요.
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} lg={6}>
              <img
                src={`${constants.s3_url}/qna/${modalData.imageUrl}.jpg`}
                alt={"문제사진"}
                style={{
                  width: "100%",
                }}
              />
            </Grid>
            <Grid xs={12} lg={6} px={2}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#262626",
                }}
              >
                AI인식내용
              </Typography>
              <Typography
                sx={{
                  color: "#8c8c8c",
                }}
              >
                아래의 내용을 확인하고 수정할 수 있어요.
              </Typography>
              <Box
                sx={{
                  background: "#3B82F6",
                  color: "#FFF",
                  borderRadius: "10px 10px 0 0",
                  padding: "10px",
                  mt: "10px",
                }}
              >
                <Typography>인식된 문제의 내용을 확인해주세요.</Typography>
              </Box>
              <LatexView
                style={{
                  background: "#FFF",
                  boxShadow: "4px 4px 10px 0 rgba(102,102,246, 0.05)",
                  padding: "10px",
                  borderRadius: "0 0 10px 10px",
                }}
              >
                {ocrText}
              </LatexView>
              <Box
                sx={{
                  background: "#262626",
                  color: "#FFF",
                  borderRadius: "10px 10px 0 0",
                  padding: "10px",
                  mt: "10px",
                }}
              >
                <Typography>텍스트 수정은 이곳에서 해주세요.</Typography>
              </Box>
              <TextField
                variant={"standard"}
                className={"custom-textfield"}
                sx={{
                  width: "100%",
                  padding: "6px 12px",
                  outline: "none",
                  background: "#262626",
                }}
                value={ocrText}
                onChange={handleOcrDataChange}
                multiline
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Dialog>
  );
}
