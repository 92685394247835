import { atom } from "recoil";
import { AtomKeys } from "../constants";
import { OcrEditModalAtomType } from "./types";

export const ocrEditModalAtom = atom<OcrEditModalAtomType>({
  key: AtomKeys.OCR_EDIT_MODAL,
  default: {
    isOpen: false,
    imageUrl: 0,
    ocrText: "",
  },
});
