import React from "react";
import { IcDefatultProps } from "../types";

export default function IHelp({ width = 24, height = 24 }: IcDefatultProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill="#3B82F6" />
      <path
        d="M7.66667 7.68701C7.66667 5.88211 10.3333 5.88211 10.3333 7.68701C10.3333 8.97622 9.12121 8.71838 9.12121 10.2654M9.12121 12.3333L9.12606 12.3277"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
