const latexSymbols = [
  'lim',
  'sum',
  'frac',
  'sqrt',
  'int',
  'therefore',
  'forall',
  'exists',
  'cdot',
  'times',
  'ldots',
  'dots',
  'sum',
  'prod',
  'int',
  'cup',
  'cap',
  'infty',
  'partial',
  'theta',
  'sigma',
  'omega',
  'alpha',
  'beta',
  'gamma',
  'delta',
  'lambda',
];

function processText(lines: string[]): string[] {
  let blockDepth = 0;
  const result = lines.map((originalLine) => {
    let changeLine = originalLine;

    changeLine = changeLine
      // \[ \] 패턴, \( \) 패턴 -> $$, $ 패턴 변경
      .replace(/\\\[|\\\]/g, '$$')
      .replace(/^(\\\\\(|\\\\\))$/, '$')
      // 오타 수정
      .replace('\\thereforea', '\\therefore')
      // 부등호와 미지수가 붙어있는 경우가 있음
      // le문법이 left를 le ft로 변경시킬 수 있음. 따라서, 임시로 left를 먼저 LEFT_TEMP로 바꾼 후
      // 부등호 전처리가 끝난 후 다시 원상복귀하는 방식을 사용
      .replace(/\\left/g, '\\LEFT_TEMP')
      // 동일한 부등호 기호를 여러 명령어로 렌더링하는 경우, 하나의 명령어로 통일
      .replace(/\\leq/g, '\\le')
      .replace(/\\geq/g, '\\ge')
      // 부등호와 미지수가 붙어있는 경우가 있음
      .replace(/\\(le|ge)([a-zA-Z])/g, '\\$1 $2')
      // Restore 'left'
      .replace(/\\LEFT_TEMP/g, '\\left')
      // 파이 기호(π, \pi)뒤에 공백(\s), 숫자(\d), 캐럿(^), 중괄호({}), 또는 언더스코어(_)가 없을 때만 공백을 추가
      .replace(/(\\pi)(?![\s\d^{}_])/g, '$1 ')
      // 로그함수(\ln, log)과 변수 분리
      .replace(/\\ln([a-zA-Z])/g, '\\ln{$1}')
      .replace(/\\log([a-zA-Z])/g, '\\log $1')
      .replace(/\\([(|()])/g, '{$1}')
      // 명령어 뒤에 바로 문자열이 붙을 경우 그 사이에 공백 추가
      .replace(/\\times([a-zA-Z])/g, '\\times $1')
      .replace(/\\ne([a-zA-Z])/g, '\\ne $1')
      .replace(/\\rm([a-zA-Z])/g, '\\rm $1')
      .replace(/\\angle([a-zA-Z])/g, '\\angle {$1}')
      .replace(/\\triangle([a-zA-Z])/g, '\\triangle $1');

    // &=를 =로 변경
    if (['&=', '=&'].some((tag) => changeLine.includes(tag))) {
      changeLine = changeLine.replace(/&=|=&/g, '=');
    }

    // \begin \end 패턴
    if (changeLine.includes('\\begin')) {
      // begin end {align} 구문은 반드시 block으로 시작해야하기 때문에 $ 가 있으면 이걸 제거하고 $$를 수동으로 붙힘
      if (blockDepth === 0) {
        changeLine = changeLine.replace(/^\$*/, '');
        changeLine = `$$${changeLine}`;
      }
      blockDepth += 1;
    }
    if (changeLine.includes('\\end')) {
      blockDepth -= 1;
      // end 구문 옆에는 다른 텍스트들이 이어질 수 있음. 하지만 end의 $$ 이후 강제로 개행함으로서
      // 오류가 나는 상황을 방지
      if (blockDepth === 0) {
        const endRegex = /\\end\{([^}]+)\}\$\$/;
        if (!endRegex.test(changeLine)) {
          changeLine = changeLine.replace(
            /\\end\{([^}]+)\}\$*/,
            (match, p1) => `\\end{${p1}}$$\n`,
          );
          // 변경 후 $$와 $$ 이후 문자열에 $가 하나만 있으면 $$ 이후 $를 추가
          changeLine = changeLine.replace(
            /(\$\$[\s\S]*?\$\$)(\n)([\s\S]*\$)/gm,
            (match, p1, p2, p3) => `${p1}${p2}$${p3}`,
          );
        }
      }
    }

    // $와 $$ 사이에 여백이 있는 경우가 있음. 예) $ 수식 $
    changeLine = changeLine.replace(/(\$)([^$]+)(\$)/g, (match, p1, p2, p3) => {
      return p1 + p2.trim() + p3;
    });
    changeLine = changeLine.replace(
      /(\$\$)([^$]+)(\$\$)/g,
      (match, p1, p2, p3) => {
        return p1 + p2.trim() + p3;
      },
    );

    // $$가 다른 word와 묶여있는 경우 예) $$ 블라블라
    // $$는 block 방식이기때문에 이후에 반드시 개행함.
    // 단 $$\begin 처럼 문법자체가 $$를 필요로하는 문법이 존재하기 때문에 해당 부분은 specialCases로 처리
    if (
      changeLine.startsWith('$$') &&
      changeLine.length > 2 &&
      changeLine[2] !== '$'
    ) {
      const specialCases = ['\\begin', '\\[', '\\('];
      if (
        !specialCases.some((specialCase) =>
          changeLine.substring(2).trimStart().startsWith(specialCase),
        )
      ) {
        changeLine = `$$\n${changeLine.substring(2)}`;
      }
    }

    // \lim, \sum 등으로 시작하는 경우 앞뒤로 인라인 수식 구분 기호($) 달렸는지 확인 후 없으면 추가
    if (
      blockDepth === 0 &&
      latexSymbols.some((symbol) => changeLine.includes(`\\${symbol}`)) &&
      !/\$\$[\s\S]*\$\$/.test(changeLine) &&
      !/\$[\s\S]*\$/.test(changeLine)
    ) {
      const trimmedLine = changeLine.trim();
      changeLine = `$${trimmedLine}$`;
    }

    // 독립적으로 존재하는 $ 나 $$는 제거
    if (/^\s*\${1,2}\s*$/.test(changeLine)) {
      changeLine = '';
    }

    return changeLine;
  });

  return result;
}

// NOTE:: markdown table 다음에 개행이 일어나지 않아 table 다음 줄에 오는 text가 table에 속하는 문제를 해결하기 위해 사용
const parseTableInMarkdown = (markdown: string): string => {
  // markdown 문자열을 LaTeX 블록($$로 둘러싸인 부분)과 줄바꿈(\n) 기준으로 분할
  const lines = markdown.split(/(\$\$[\s\S]*?\$\$|\n)/g);
  const newLines: string[] = [];
  let inTable = false;

  const preprocessLatex = processText(lines);
  preprocessLatex.forEach((line, index) => {
    if (line === '\n' || line.trim() === '') {
      return;
    }

    // LaTeX 블록은 그대로 결과에 추가
    if (line.startsWith('$$') && line.endsWith('$$')) {
      newLines.push(line);
      return;
    }

    // 현재 라인이 Markdown 테이블 구문(라인이 '|'로 시작)인 경우
    if (line.trim().startsWith('|')) {
      // 아직 테이블 상태가 아니고, 이전 라인이 존재하는 경우
      // 테이블 시작 전에 빈 줄을 추가 (테이블과 다른 내용 사이에 여백을 주기 위해)
      if (!inTable && index > 0) {
        newLines.push('');
      }
      inTable = true;
      newLines.push(line);
      return;
    }

    // 이전 라인까지는 테이블 상태였으나, 현재 라인은 테이블이 아닌 경우
    // 테이블 종료 후 빈 줄을 추가
    if (inTable && !line.trim().startsWith('|')) {
      inTable = false;
      newLines.push('');
    }

    // 테이블도 아니고 LaTeX 블록도 아닌 텍스트는 원본 그대로 추가
    newLines.push(line);
  });

  // 마지막 줄이 테이블에 속한 경우, 테이블을 닫기 위해 빈 줄을 추가
  if (inTable) {
    newLines.push('');
  }

  return newLines.join('\n');
};

export const preprocessLatex = (latexString: string): string => {
  // NOTE: 중요
  // 지금 저처리가 line by line으로 돌아가고 있음. 따라서, $$ 를 개행하고 이후 삭제해야하는 로직을 돌리는 등의 2차 작업이 필요한 경우
  // $$ 개행까지만 적용되고 삭제되는 로직이 적용되지않음 (이미 해당 라인은 지나갔기 때문)
  // 따라서, line by line 방식을 유지한다면, 전처리기를 2번 돌려줘야함
  let markdownWithLatex = parseTableInMarkdown(latexString);
  markdownWithLatex = parseTableInMarkdown(markdownWithLatex);
  return markdownWithLatex;
};
