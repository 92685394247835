import { Box, Button } from "@mui/material";
import React, { JSX } from "react";

type IconTextButtonProps = {
  buttonText: string;
  iconElement: JSX.Element;
  onClick?: () => void;
};

export default function IconTextButton({
  buttonText,
  iconElement,
  onClick,
}: IconTextButtonProps): JSX.Element {
  return (
    <Button
      sx={{
        color: "#363636",
        display: "flex",
        gap: "8px",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(231,231,231,0.3)",
          padding: "4px",
          borderRadius: "99px",
        }}
      >
        {iconElement}
      </Box>
      {buttonText}
    </Button>
  );
}
