import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  circularProgressClasses,
  Drawer,
  Stack,
  Typography,
} from "@mui/material";
import { LatexView } from "../../../../components/atoms/latexView";
import IconTextButton from "./components/Button";
import IShare from "../../../../components/atoms/icons/share";
import ICopyRight from "../../../../components/atoms/icons/copyRight";
import { ocrEditModalAtom } from "../../../../application/recoils/modal/atoms";
import { useRecoilState } from "recoil";
import IHelp from "../../../../components/atoms/icons/help";
import IAi from "../../../../components/atoms/icons/ai";
import { useSocket } from "../../../../providers/socket";
import { useSaveSemohaeAnswer } from "../../../../api/semohae";
import { semohaeSearchAtom } from "../../../../application/recoils/search/atoms";
import html2canvas from "html2canvas";
import store from "../../../../asset/store";

type SemohaeChatProps = {
  content: string;
  imageUrl?: string;
  onSendAnswer: (semohaeContent) => void;
  answerList?: any[];
  questionId: number;
  ownerId: string;
  questionContent?: string;
};

const styles = {
  fixedButton: {
    position: "fixed",
    right: "20px",
    bottom: "85px",
    background: "transparent",
  },
  button: {
    background: "#1F1F1F",
    borderRadius: "99px",
    py: "20px",
  },
  box: {
    maxWidth: "80vw",
    borderRadius: "10px",
    padding: "16px",
    background: "#FFF",
    marginLeft: "auto",
    marginY: "8px",
  },
  stackButton: {
    color: "#363636",
    background: "rgba(231,231,231,0.3)",
    borderRadius: "99px",
    display: "flex",
    gap: "8px",
  },
  textButton: {
    color: "#cccccc",
  },
};

export default function SemohaeChat({
  content,
  imageUrl,
  onSendAnswer,
  answerList,
  questionId,
  ownerId,
  questionContent,
}: SemohaeChatProps) {
  const [ocrEditModalData, setOpenOcrEditModal] = useRecoilState(ocrEditModalAtom);
  const [loading, setLoading] = useRecoilState(semohaeSearchAtom);
  const [showSemohaeButton, setShowSemohaeButton] = useState(false);
  const [semohaeContent, setSemohaeContent] = useState(content ?? "");
  const [firstMessageReceived, setFirstMessageReceived] = useState(false);
  const [copySizeModal, setCopySizeModal] = useState(false);
  const [loadingText, setLoadingText] = useState("요청중입니다");
  const mutation = useSaveSemohaeAnswer();
  const { semohaeSocket } = useSocket();
  const semohaeAnswerRef = useRef<HTMLDivElement>(null);
  const copySize = useRef<"mobile" | "tablet">("mobile");
  const { isMobile } = store;

  const handleOpenOcrEditModal = useCallback(() => {
    setOpenOcrEditModal({
      ocrText: ocrEditModalData.ocrText ?? questionContent,
      imageUrl: questionId,
      isOpen: true,
    });
  }, [setOpenOcrEditModal, questionId, ocrEditModalData.ocrText, questionContent]);

  const toggleSemohaeButton = useCallback(() => {
    setShowSemohaeButton((prev) => !prev);
  }, []);

  useEffect(() => {
    const hasCtype70 = answerList.some((talk) => talk.ctype === 70);
    const hasCtype71 = answerList.some((talk) => talk.ctype === 71);
    setShowSemohaeButton(hasCtype70 && !hasCtype71);
  }, [answerList]);

  useEffect(() => {
    setLoadingText("요청중입니다");
    const timer = setTimeout(() => {
      setLoadingText("데이터를 받아오고 있어요");
    }, 20000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (semohaeSocket) {
      semohaeSocket.on("message", (message) => {
        if (!firstMessageReceived) {
          setLoading(false);
          setFirstMessageReceived(true);
        }
        setSemohaeContent(message.text);
        if (message.end) {
          mutation.mutate({
            questionId: questionId,
            ownerId: ownerId,
            semohaeAnswer: message.text,
          });
        }
      });

      return () => {
        semohaeSocket.off("message");
      };
    }
  }, [semohaeSocket, questionId, ownerId, mutation]);

  const handleCallAnswer = () => {
    toggleSemohaeButton();

    if (semohaeContent) return;

    if (semohaeSocket) {
      setLoading(true);
      semohaeSocket.emit("message", {
        id: questionId,
        message: questionContent,
      });
    }
  };

  const downloadImage = (dataUrl: string) => {
    const a = document.createElement("a");
    a.href = `http://native_download?filename=${questionId}.jpg &filepath=${dataUrl}`;
    a.download = `${questionId}.jpg`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const convertMessageToImage = useCallback(async (): Promise<string> => {
    const contentBox = semohaeAnswerRef.current;
    if (!contentBox) {
      return "";
    }

    const clonedContentBox = contentBox.cloneNode(true) as HTMLElement;
    Object.assign(clonedContentBox.style, {
      width: copySize.current === "mobile" ? "375px" : "900px",
      height: "auto",
      position: "absolute",
      left: "-9999px",
      overflow: "visible",
      padding: "20px",
      whiteSpace: "unset",
    });
    clonedContentBox.classList.add("copy-latex-container");

    document.body.appendChild(clonedContentBox);
    clonedContentBox.style.width = `${clonedContentBox.scrollWidth + 50}px`;

    const canvas = await html2canvas(clonedContentBox);

    document.body.removeChild(clonedContentBox);
    return canvas.toDataURL("image/png");
  }, []);

  const handleCapture = useCallback(async () => {
    const dataUrl = await convertMessageToImage();
    if (!dataUrl) {
      return;
    }

    try {
      const blob = await (await fetch(dataUrl)).blob();

      if (!blob) return;

      const url = URL.createObjectURL(blob);

      if (isMobile()) {
        downloadImage(url);
        alert("다운로드가 완료되었습니다.");
      } else {
        try {
          const clipboardItem = new ClipboardItem({ "image/png": blob });
          await navigator.clipboard.write([clipboardItem]);
          alert("복사가 완료되었습니다.");
        } catch (error) {
          downloadImage(url);
          alert("다운로드가 완료되었습니다.");
        }
      }
    } catch (error) {
      console.error("Error capturing content:", error);
    }
  }, [convertMessageToImage, isMobile]);

  const handleStartCapture = useCallback(() => {
    if (isMobile()) {
      alert(`다운로드를 시작합니다.`);
    } else {
      alert(`복사를 시작합니다.`);
    }
    handleCapture();
  }, [isMobile, handleCapture]);

  const setCopySize = useCallback((size: "mobile" | "tablet") => {
    setCopySizeModal(false);
    copySize.current = size;
    handleStartCapture();
  }, []);

  return (
    <>
      {!showSemohaeButton ? (
        <div style={styles.fixedButton as React.CSSProperties}>
          <Button variant="contained" sx={styles.button} onClick={handleCallAnswer}>
            <IAi />
            Ai답변 받기
          </Button>
        </div>
      ) : (
        <Box sx={styles.box}>
          {!loading ? (
            <>
              <Box ref={semohaeAnswerRef}>
                <LatexView>{semohaeContent}</LatexView>
              </Box>
              <Stack>
                <Stack direction={"row"} justifyContent={"space-around"}>
                  <IconTextButton
                    buttonText={"답변 보내기"}
                    iconElement={<IShare width={18} height={18} />}
                    onClick={() => onSendAnswer(semohaeContent)}
                  />
                  <IconTextButton
                    buttonText={"답변 복사"}
                    iconElement={<ICopyRight width={18} height={18} />}
                    onClick={() => setCopySizeModal(true)}
                  />
                </Stack>
                <Button sx={styles.stackButton} onClick={handleOpenOcrEditModal}>
                  <IHelp width={18} height={18} />
                  AI가 인식한 문제를 수정할래요.
                </Button>
                <Button variant={"text"} sx={styles.textButton} onClick={toggleSemohaeButton}>
                  접기
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  sx={(theme) => ({
                    color: "#1a90ff",
                    animationDuration: "550ms",
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                    ...theme.applyStyles("dark", {
                      color: "#308fe8",
                    }),
                  })}
                  size={40}
                  thickness={4}
                />
                <Typography>{loadingText}</Typography>
              </Box>
            </>
          )}
          <Drawer
            anchor="bottom"
            open={copySizeModal}
            onClose={() => setCopySizeModal(false)}
            className="copySizeModal"
          >
            <div
              style={{
                padding: "16px",
              }}
            >
              <Typography>이미지 사이즈를 선택해주세요.</Typography>
              <Stack direction="row" justifyContent="center" height="80px" p={2} gap={2}>
                <Button variant="outlined" onClick={() => setCopySize("mobile")}>
                  모바일 사이즈
                </Button>
                <Button variant="outlined" onClick={() => setCopySize("tablet")}>
                  태블릿 사이즈
                </Button>
              </Stack>
            </div>
          </Drawer>
        </Box>
      )}
    </>
  );
}
