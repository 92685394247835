import MarkdownIt from "markdown-it";

import { katex } from "./latex/katexCutsom";

function replaceCharacter(markdown: string): string {
  return markdown.replace(//g, "≠");
}

function getMarkdownInstance(throwOnError: boolean = false) {
  const md = new MarkdownIt({ linkify: true, breaks: true });
  md.renderer.rules.heading_open = (tokens, idx) => {
    const token = tokens[idx];
    const level = token.tag.substring(1);
    return `<h${level} style="color: #E16B0A;">`;
  };
  md.renderer.rules.strong_open = (tokens, idx) => {
    const nextToken = tokens[idx + 1];
    if (nextToken && nextToken.type === "text" && /^\d+\.\s/.test(nextToken.content)) {
      return '<strong style="color: #E16B0A;">';
    }
    return "<strong>";
  };
  md.renderer.rules.strong_close = (tokens, idx) => {
    const prevToken = tokens[idx - 1];
    if (prevToken && prevToken.type === "text" && /^\d+\.\s/.test(prevToken.content)) {
      return "</strong>";
    }
    return "</strong>";
  };

  // MarkdownIt에 katex custom plugin을 추가하여 markdown과 Latex 수식을 함께 처리
  md.use(katex, {
    output: "html",
    throwOnError,
    errorColor: "red",
  });

  return md;
}

export function getRenderedMarkdown(markdown: string, throwOnError: boolean = false) {
  const markdownRenderer = getMarkdownInstance(throwOnError);
  const renderedMarkdown = markdownRenderer.render(markdown);
  return replaceCharacter(renderedMarkdown);
}
